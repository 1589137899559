import React, { Fragment, useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import NotificationTable from "../components/NotificationTable";
import UserTable from "../components/UserTable";
import Grid from "@mui/material/Grid";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
import { useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import v from "voca";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import GeneralUtility from "../utils/GeneralUtility";
import { DateTime } from "luxon";




const UserEditPage = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } =
    getConfig();

  const {
    isLoading,
    error,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  // Get a specific query parameter
  const { target_username } = useParams();

  let targetUsername = target_username;

  console.log(`UserEditPage targetUsername: ${targetUsername}`);

  const [itemList, setItemList] = useState(undefined);

  const [userInfo, setUserInfo] = useState(undefined);


  const [effectiveSinceDate, setEffectiveSinceDate] = useState(userInfo?.study_parameter?.effective_since != undefined ? DateTime.fromISO(userInfo?.study_parameter?.effective_after).toJSDate() : undefined);

  const [effectiveBeforeDate, setEffectiveBeforeDate] = useState(userInfo?.study_parameter?.effective_before != undefined ? DateTime.fromISO(userInfo?.study_parameter?.effective_before).toJSDate() : undefined);


  const callApi = async (domain = apiOrigin, path = "/api/external", options = {}) => {
    console.log(`callApi: ${domain}${path}`);
    try {
      const token = await getAccessTokenSilently();

      const { body, ...otherOptions } = options;

      let optionObj = {
        ...otherOptions,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (body != undefined) {
        optionObj.body = JSON.stringify(body);
      }

      const response = await fetch(`${domain}${path}`, optionObj);

      console.log(`response.ok: ${JSON.stringify(response.ok)}`);

      if (!response.ok) {
        console.log(response.status, response.statusText);
      } else {
        const responseData = await response.json();
        console.log(`responseData: ${JSON.stringify(responseData)}`);

        if (v.endsWith(path, "/info")) {
          setUserInfo(responseData);

          if (responseData.study_parameter?.effective_since != undefined) {
            setEffectiveSinceDate(DateTime.fromISO(responseData.study_parameter.effective_since).toJSDate());
          }
          if (responseData.study_parameter?.effective_before != undefined) {
            setEffectiveBeforeDate(DateTime.fromISO(responseData.study_parameter.effective_before).toJSDate());
          }



          // avoid too many defaults
          /*
          if (responseData.state?.timezone != undefined) {

            let defaultStartDate = GeneralUtility.getLocalTime(DateTime.now().toJSDate(), userInfo?.state?.timezone).plus({ days: 1 }).set({ hour: 5, minute: 0, second: 0, millisecond: 0 }).toJSDate();


            if (responseData.study_parameter?.effective_since != undefined) {
              setEffectiveSinceDate(DateTime.fromISO(responseData.study_parameter.effective_since).toJSDate());
            }
            else {
              setEffectiveSinceDate(defaultStartDate);
            }

            if (responseData.study_parameter?.effective_before != undefined) {
              setEffectiveBeforeDate(DateTime.fromISO(responseData.study_parameter.effective_before).toJSDate());
            }
            else {
              setEffectiveBeforeDate(GeneralUtility.getLocalTime(defaultStartDate, userInfo?.state?.timezone).plus({ days: 30 }).toJSDate());
            }

          }
          else {
            // no timezone
            if (responseData.study_parameter?.effective_since != undefined) {
              setEffectiveSinceDate(DateTime.fromISO(responseData.study_parameter.effective_since).toJSDate());
            }
            else {
              setEffectiveSinceDate(undefined);
            }

            if (responseData.study_parameter?.effective_before != undefined) {
              setEffectiveBeforeDate(DateTime.fromISO(responseData.study_parameter.effective_before).toJSDate());
            }
            else {
              setEffectiveBeforeDate(undefined);
            }
          }
          */
        }
        else if (v.endsWith(path, "/status")) {
          // do nothing
        }

        return responseData;
      }


    } catch (error) {
      console.log(`catch error: ${error.error}`);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(
      `UserEditPage useEffect, isAuthenticated:${isAuthenticated}, userInfo: ${userInfo}`
    );
    // load data from the database
    if (isAuthenticated && userInfo == undefined) {
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
    }
  }, []);

  if (isAuthenticated) {
    console.log(`UserEditPage user: ${JSON.stringify(user, null, 2)}`);
  }

  console.log(`UserEditPage`);

  return (
    <Fragment>
      <h1>UserEdit</h1>
      <h2 className="project-text">You are editing user info for: {targetUsername}</h2>
      <br />
      <br />
      <h2 className="project-text">Study parameter</h2>
      <div>Status: {JSON.stringify(userInfo?.study_parameter?.status)}</div>
      <br />
      <Fragment>
        <FormControl fullWidth>
          <InputLabel id="user-status-label">Study status</InputLabel>
          <Select
            labelId="user-status-label"
            id="select-status"
            value={userInfo?.study_parameter?.status ? userInfo?.study_parameter?.status : ""}
            label="Status"
            onChange={(event) => {
              // ToDo: need to pass in bearer token for a secured call
              // fetchData(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${targetUsername}/study_parameter/status`, "PUT", {}, { status: event.target.value })

              let status = event.target.value;
              console.log(`onChange status: ${status}`);

              //setStatus(status);

              let body = {
                status: status,
              };

              switch (status) {
                case "active":
                  body["active_at"] = new Date();
                  break;
                case "inactive":
                  body["inactive_at"] = new Date();
                  break;
              }

              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/status`, { method: "PUT", body: body })
                .then((response) => {
                  console.log(`response: ${JSON.stringify(response)}`);
                  callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
                })
            }}
          >
            <MenuItem value={"active"}>active</MenuItem>
            <MenuItem value={"inactive"}>inactive</MenuItem>
          </Select>
        </FormControl>
        <br />
        <Fragment>
          <div>Active at: {userInfo?.study_parameter?.active_at}</div>
          <br />
          <div>Inactive at: {userInfo?.study_parameter?.status == "inactive"? userInfo?.study_parameter?.inactive_at: ""}</div>
          <br />
        </Fragment>
        <br />
        <Fragment>
          <div>Timezone: {userInfo?.state?.timezone}</div>
        </Fragment>
      </Fragment>
      <br />
      <br />
      {
        userInfo?.state?.timezone != undefined ? (<Fragment>

          <div>Effective Range (only if the status is "active")</div>
          <br />
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
            <DateTimePicker
              disabled={userInfo?.study_parameter?.status != "active"}
              label="Effective Since"
              value={DateTime.fromJSDate(effectiveSinceDate)}
              onChange={(newValue) => {
                setEffectiveSinceDate(newValue.toJSDate());
                setEffectiveBeforeDate(newValue.plus({ days: 30 }).toJSDate());

                Promise.all([
                  callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_since`, { method: "PUT", body: { effective_since: newValue.toJSDate() } }),
                  callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_before`, { method: "PUT", body: { effective_before: newValue.plus({ days: 30 }).toJSDate() } })])
                  .then((response) => {
                    console.log(`response: ${JSON.stringify(response)}`);
                    callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
                  })

              }}
            />
            <DateTimePicker
              disabled={userInfo?.study_parameter?.status != "active"}
              label="Effective Before"
              value={DateTime.fromJSDate(effectiveBeforeDate)}
              onChange={(newValue) => {
                setEffectiveBeforeDate(newValue.toJSDate());

                let body = {
                  effective_before: newValue.toJSDate(),
                };

                Promise.all([
                  callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_before`, { method: "PUT", body: body })
                ])
                  .then((response) => {
                    console.log(`response: ${JSON.stringify(response)}`);
                    callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
                  })
              }}
            />
          </LocalizationProvider>
          <br />
          <br />
          <Fragment>
          <Button variant="contained" 
          disabled={userInfo?.study_parameter?.status != "active"}
          onClick={(event) => {

            let defaultStartDate = GeneralUtility.getLocalTime(DateTime.now().toJSDate(), userInfo?.state?.timezone).plus({ days: 1 }).set({ hour: 5, minute: 45, second: 0, millisecond: 0 }).toJSDate();

            let defaultEndDate = GeneralUtility.getLocalTime(defaultStartDate, userInfo?.state?.timezone).plus({ days: 30 }).toJSDate();


            setEffectiveSinceDate(defaultStartDate);
            setEffectiveBeforeDate(defaultEndDate);

            // need to update to the server
            Promise.all([
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_since`, { method: "PUT", body: { effective_since: defaultStartDate } }),
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_before`, { method: "PUT", body: { effective_before: defaultEndDate } })])
              .then((response) => {
                console.log(`response: ${JSON.stringify(response)}`);
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
              })
          }}> Start at 5:45 am tomorrow for 30 days</Button>
          &nbsp;&nbsp;
          <Button variant="contained" 
          disabled={userInfo?.study_parameter?.status != "active"}
          onClick={(event) => {

            let defaultStartDate = GeneralUtility.getLocalTime(DateTime.now().toJSDate(), userInfo?.state?.timezone).toJSDate();

            let defaultEndDate = GeneralUtility.getLocalTime(defaultStartDate, userInfo?.state?.timezone).plus({ days: 30 }).toJSDate();


            setEffectiveSinceDate(defaultStartDate);
            setEffectiveBeforeDate(defaultEndDate);

            // need to update to the server
            Promise.all([
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_since`, { method: "PUT", body: { effective_since: defaultStartDate } }),
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_before`, { method: "PUT", body: { effective_before: defaultEndDate } })])
              .then((response) => {
                console.log(`response: ${JSON.stringify(response)}`);
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
              })
          }}> Start now for 30 days</Button>
          &nbsp;&nbsp;
          <Button variant="contained" 
          disabled={userInfo?.study_parameter?.status != "active"}
          onClick={(event) => {

            let defaultStartDate = GeneralUtility.getLocalTime(DateTime.now().toJSDate(), userInfo?.state?.timezone).toJSDate();

            let defaultEndDate = GeneralUtility.getLocalTime(defaultStartDate, userInfo?.state?.timezone).plus({ years: 1 }).toJSDate();


            setEffectiveSinceDate(defaultStartDate);
            setEffectiveBeforeDate(defaultEndDate);

            // need to update to the server
            Promise.all([
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_since`, { method: "PUT", body: { effective_since: defaultStartDate } }),
              callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/study_parameter/effective_before`, { method: "PUT", body: { effective_before: defaultEndDate } })])
              .then((response) => {
                console.log(`response: ${JSON.stringify(response)}`);
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/users/${targetUsername}/info`);
              })
          }}> Start now for a year</Button>
          </Fragment>
        </Fragment>) : null
      }
    </Fragment>
  );
};

export default UserEditPage;

//             <MenuItem value={null}>(undefined)</MenuItem>
/*
<Grid item xs={2}>
<span style={{ float: "right" }}><Button
    variant="contained"
    onClick={() => {
        callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users/set-all-timezone")
            .then((response) => {
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users")
            })
    }}
>
    Set timezone
</Button></span>

</Grid>
*/
