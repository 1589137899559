import React, { Fragment, useState, useEffect } from "react";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import ResponseTable from "../components/ResponseTable";
import RecordTable from "../components/RecordTable";
import { DateTime } from "luxon";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
import ActionRecordTable from "../components/ActionRecordTable";
const ActionRecordPage = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();

  const {
    isLoading, error, isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();


  const [itemList, setItemList] = useState(undefined);

  const [endDate, setEndDate] = useState(DateTime.now().toJSDate());
  const [startDate, setStartDate] = useState(DateTime.now().minus({days: 365}).toJSDate());

  const callApi = async (domain = apiOrigin, path = "/api/responses") => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log(response.status, response.statusText);
      } else {
        const responseData = await response.json();
        console.log(`ItemList: ${JSON.stringify(responseData)}`);

        setItemList(responseData);
      }


    } catch (error) {
      console.log(`catch error: ${error.error}`);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(`useEffect, isAuthenticated:${isAuthenticated}, itemList: ${itemList}`);
    if (isAuthenticated && itemList == undefined) {
      // decide-true, decide-false, or all
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/action_records/${"all"}/${DateTime.fromJSDate(startDate).toISO()}/${DateTime.fromJSDate(endDate).toISO()}`);
    }
  });

  if (isAuthenticated) {
    console.log(`user: ${JSON.stringify(user, null, 2)}`);
  }

  


  console.log(`ActionRecordPage`);

  return (<Fragment>
    <h1>Action Record</h1>
    <div>{DateTime.fromJSDate(startDate).toLocaleString(DateTime.DATETIME_FULL)} to {DateTime.fromJSDate(endDate).toLocaleString(DateTime.DATETIME_FULL)}</div>
    <br />
    <ObjectListExortToolbar filePrefix={"ActionRecord"} infoList={itemList} userInfo={user}></ObjectListExortToolbar>
    <br />
    <ActionRecordTable itemList={itemList}></ActionRecordTable>
  </Fragment>);
};

export default ActionRecordPage;
