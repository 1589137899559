import React, { Fragment, useState, useEffect } from "react";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import ResponseTable from "../components/ResponseTable";
import SurveyTable from "../components/SurveyTable";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
const SurveyPage = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();

  const {
    isLoading, error, isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();


  const [itemList, setItemList] = useState(undefined);

  const callApi = async (domain = apiOrigin, path = "/api/responses") => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log(response.status, response.statusText);

      } else {
        const responseData = await response.json();
        console.log(`ItemList: ${JSON.stringify(responseData)}`);

        setItemList(responseData);
      }
    } catch (error) {
      console.log(`catch error: ${error.error}`);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(`useEffect, isAuthenticated:${isAuthenticated}, itemList: ${itemList}`);
    if (isAuthenticated && itemList == undefined) {
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/surveys");
    }

  });

  if (isAuthenticated) {
    console.log(`user: ${JSON.stringify(user, null, 2)}`);
  }

  


  console.log(`SurveyPage`);

  return (<Fragment>
    <h1>Survey</h1>
    <br />
    <ObjectListExortToolbar filePrefix={"Survey"} infoList={itemList} userInfo={user}></ObjectListExortToolbar>
    <br />
    <SurveyTable itemList={itemList}></SurveyTable>
  </Fragment>);
};

export default SurveyPage;
