import React, { Fragment, useState, useEffect } from "react";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import NotificationTable from "../components/NotificationTable";
import Button from "@mui/material/Button";
import UserTable from "../components/UserTable";
import Grid from "@mui/material/Grid";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
import { useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import RecordTable from "../components/RecordTable";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import v from "voca";


const UserRecordPage = () => {
    const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } =
        getConfig();

    const {
        isLoading,
        error,
        isAuthenticated,
        user,
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    // Get a specific query parameter
    const { target_username } = useParams();

    let targetUsername = target_username;

    console.log(`UserRecordPage targetUsername: ${targetUsername}`);

    const [itemList, setItemList] = useState(undefined);

    const [userInfo, setUserInfo] = useState(undefined);

    //const [status, setStatus] = useState(undefined);

    const callApi = async (domain = apiOrigin, path = "/api/external", options = {}) => {
        console.log(`callApi: ${domain}${path}`);
        try {
            const token = await getAccessTokenSilently();

            const { body, ...otherOptions } = options;

            let optionObj = {
                ...otherOptions,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (body != undefined) {
                optionObj.body = JSON.stringify(body);
            }

            const response = await fetch(`${domain}${path}`, optionObj);

            console.log(`response.ok: ${JSON.stringify(response.ok)}`);

            if (!response.ok) {
                console.log(response.status, response.statusText);
            } else {
                const responseData = await response.json();
                console.log(`responseData: ${JSON.stringify(responseData)}`);

                setItemList(responseData);

                return responseData;
            }


        } catch (error) {
            console.log(`catch error: ${error.error}`);
        }
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        console.log(
            `UserRecordPage useEffect, isAuthenticated:${isAuthenticated}, userInfo: ${userInfo}`
        );
        // load data from the database
        if (isAuthenticated && userInfo == undefined) {
            callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/records/user/${targetUsername}`);
        }
    }, []);

    if (isAuthenticated) {
        console.log(`UserRecordPage user: ${JSON.stringify(user, null, 2)}`);
    }

    console.log(`UserRecordPage`);

    return (
        <Fragment>
            <h1>UserRecord</h1>
            <h2 className="project-text">You are viewing records for: {targetUsername}</h2>
            <br />
            <br />
            <ObjectListExortToolbar filePrefix={"UserRecord"} infoList={itemList} userInfo={user}></ObjectListExortToolbar>
            <br />
            <RecordTable itemList={itemList}></RecordTable>
        </Fragment>
    );
};

export default UserRecordPage;

//             <MenuItem value={null}>(undefined)</MenuItem>
/*
<Grid item xs={2}>
<span style={{ float: "right" }}><Button
    variant="contained"
    onClick={() => {
        callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users/set-all-timezone")
            .then((response) => {
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users")
            })
    }}
>
    Set timezone
</Button></span>

</Grid>
*/
