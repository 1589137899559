import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero">
    <img className="mb-3 app-logo" src={logo} alt="Logo" width="120" />
    <h1 className="mb-4">MiWaves {process.env.REACT_APP_API_SERVER_URL == "https://miwaves.app"? "Production": "Testing"} Server Admin Dashboard</h1>
  </div>
);

export default Hero;

//<img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/