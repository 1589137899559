import React, { Fragment, useState, useEffect } from "react";

const PrivacyPage = () => {

  console.log(`PrivacyPage`);

  return (<Fragment>
    <h1>Privacy Policy</h1>
    <br />
    <div>(TBD)</div>
  </Fragment>);
};

export default PrivacyPage;
