import React, { Fragment, useContext } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";

import { UserContext } from "../App";

const Main = () => {
    const value = React.useContext(UserContext);  

    console.log(`Main: roleList: ${JSON.stringify(value)}`);

    let roleList = value;

    return <Fragment>
    <Hero />
    <h3 className="text-center">Your role: {roleList != undefined && roleList.length > 0? roleList.map((roleInfo) => {return roleInfo.name;}).join(","): "(no roles)"}</h3>
    <hr />
    {
        roleList != undefined && roleList.some((roleInfo) => {return roleInfo.name == "Staff"})? <Content />: null
    }
    
  </Fragment>;

}
 
export default Main;
