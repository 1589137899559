import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export default function CredentialTable({ itemList }) {
    const columns = [
        { field: 'api_name', headerName: 'API', sortable: true, width: 60 },
        { field: 'identifier', headerName: 'Identifier', sortable: true, width: 120 },
        { field: 'content', headerName: 'Content', sortable: true, width: 450 },
        { field: 'username', headerName: 'Username', sortable: true, width: 180 },
        {
            field: 'created_at',
            headerName: 'Created At',
            sortable: true,
            width: 140
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            sortable: true,
            width: 140
        }
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

