import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import voca from "voca";

export default function EventTable({ itemList }) {
    const columns = [
        { field: 'name', headerName: 'Event', sortable: true, width: 100 },
        { field: 'userName', headerName: 'Username', sortable: true, width: 100, valueGetter: (params) => {
            let result = "";
            if (params.row.userName != undefined) {
                result = `${voca.replaceAll(params.row.userName, "@miwaves.app", "")}`; //params.row.targetInfo;
            }
            return result;
        } },
        { field: 'status', headerName: 'Status', sortable: true, width: 100 },
        { field: 'providedTimestamp', headerName: 'Time', sortable: true, width: 100 },
        { field: 'updateDescription', headerName: 'Update Desc.', sortable: true, width: 180, valueGetter: (params) => {
            let result = "";
            if (params.row.updateDescription != undefined) {
                result = `${JSON.stringify(params.row.updateDescription)}`;
            }
            return result;
        } },
        { field: 'targetType', headerName: 'Target Type', sortable: true, width: 100 },
        { field: 'targetName', headerName: 'Target Name', sortable: true, width: 100, valueGetter: (params) => {
            let result = "";
            if (params.row.targetInfo != undefined) {
                result = `${params.row.targetInfo.name}`;
            }
            return result;
        } },
        { field: 'appStatus', headerName: 'App Status', sortable: true, width: 100, valueGetter: (params) => {
            let result = "";
            if (params.row.targetInfo != undefined && params.row.targetInfo.app_status != undefined) {
                result = `${params.row.targetInfo.app_status}`;
            }
            return result;
        } },
        { field: 'targetPath', headerName: 'Path', sortable: true, width: 100, valueGetter: (params) => {
            let result = "";
            if (params.row.targetInfo != undefined && params.row.targetInfo.path != undefined) {
                result = `${params.row.targetInfo.path}`; //params.row.targetInfo;
            }
            return result;
        } },
        { field: 'action', headerName: 'Action', sortable: true, width: 100 },


        { field: 'promptType', headerName: 'Prompt Type', sortable: true, width: 100 },
        { field: 'promptId', headerName: 'Prompt Id', sortable: true, width: 100 },
        { field: 'responseId', headerName: 'Response Id', sortable: true, width: 100 },
        { field: 'deviceTime', headerName: 'Device Time', sortable: true, width: 100 }
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

