import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from "luxon";
export default function AnalyticTable({ itemList }) {
    const columns = [
        { field: 'username', headerName: 'Username', sortable: true, width: 180 },
        { field: 'uuid', headerName: 'Device ID', sortable: true, width: 180 },
        { field: 'target_type', headerName: 'Type', sortable: true, width: 100 },
        {
            field: 'app_status', headerName: 'App', sortable: true, width: 100, valueGetter: (params) => {
                let result = "";
                if (params.row.target_info != undefined && params.row.target_info.app_status != undefined) {
                    result = params.row.target_info.app_status;
                }
                return result;
            }
        },
        {
            field: 'screen', headerName: 'Screen', sortable: true, width: 100, valueGetter: (params) => {
                let result = "";
                if (params.row.target_info != undefined && params.row.target_info.name != undefined) {
                    result = params.row.target_info.name;
                }
                return result;
            }
        },
        {
            field: 'path', headerName: 'Path', sortable: true, width: 200, valueGetter: (params) => {
                let result = "";
                if (params.row.target_info != undefined && params.row.target_info.path != undefined) {
                    result = params.row.target_info.path;
                }
                else if (params.row.target_info != undefined && params.row.target_info.url != undefined) {
                    result = params.row.target_info.url;
                }
                return result;
            }
        },
        {
            field: 'resend', headerName: 'Resend', sortable: true, width: 100, valueGetter: (params) => {
                let result = "";
                if (params.row.target_info != undefined && params.row.target_info.resend != undefined) {
                    result = params.row.target_info.resend;
                }
                return result;
            }
        },
        { field: 'action', headerName: 'Action', sortable: true, width: 180 },
        {
            field: 'app_version',
            headerName: 'App Version',
            sortable: true,
            width: 150,
            valueGetter: (params) =>
            params.row.app_version,
        },
        {
            field: 'device_time',
            headerName: 'Device Time',
            sortable: true,
            width: 150,
            valueGetter: (params) =>
            params.row.device_time,
        },
        {
            field: 'created_at_your_time',
            headerName: 'Created At (your time)',
            sortable: true,
            width: 150,
            valueGetter: (params) =>
                DateTime.fromISO(params.row.created_at).toLocaleString(DateTime.DATETIME_FULL),
        }
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

