import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import v from "voca";

export default function RecordTable({ itemList }) {
    const columns = [
        { field: 'triggerId', headerName: 'Rule', sortable: true, width: 120, valueGetter: (params) => {
            let result = "";
            result = params.row.triggerId
            result = v.replaceAll(result, "Demo", "");
            result = v.replaceAll(result, "MiWaves", "");
            result = v.replaceAll(result, "Trigger", "");
            return result;
        }},
        { field: 'username', headerName: 'User', sortable: true, width: 65, valueGetter: (params) => {
            let result = "";
            result = params.row.user.username.split("@")[0];
            return result;
        }},
        { field: 'timezone', headerName: 'Timezone', sortable: true, width: 120, valueGetter: (params) => {
            let result = "";
            if(params.row.user.state != undefined && params.row.user.state.timezone != undefined){
                result = params.row.user.state.timezone;
            }
            return result;
        }},
        { field: 'event', headerName: 'Event', sortable: true, width: 60, valueGetter: (params) => {
            let result = "";
            if(params.row.record.shouldDecideRecord.record.resultType == "composite"){
                let recordList = params.row.record.shouldDecideRecord.record.recordList;

                let reducedList = recordList.map((recordInfo) => {
                    const {name, resultType, validity, value, eventName, ...rest} = recordInfo.record;

                    return eventName;
                });

                if( reducedList.length > 1){
                    result = JSON.stringify(reducedList);
                }
                else{
                    result = reducedList[0];
                }
                
            }
            else{
                result = params.row.record.shouldDecideRecord.record.eventName;
            }
            return result;
        }},
        { field: 'decide', headerName: 'Activate', sortable: true, width: 70, valueGetter: (params) => {
            let result = "";
            let shouldDecideRecord = params.row.record.shouldDecideRecord;

            if( shouldDecideRecord != undefined &&  shouldDecideRecord.record != undefined &&  shouldDecideRecord.record.value != undefined){
                result = JSON.stringify(shouldDecideRecord.record.value)
            }
            else if(shouldDecideRecord != undefined && shouldDecideRecord != null){
                result = JSON.stringify(shouldDecideRecord);
            }
            else{
                // nothing
                
            }
            //result = params.row.record.shouldDecideRecord.record.value;
            
            return result;
        }},
        { field: 'executionTime', headerName: 'Exec Time', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";

            if(params.row.executionTime != undefined){
                result = params.row.executionTime.toFixed(5);
            }
            return result;
        }},
        { field: 'memoryUsage', headerName: 'Memory', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";
            // "used_heap_size": 1434296
            if(params.row.memoryUsage != undefined){
                result = `${(params.row.memoryUsage["used_heap_size"]/1000000).toFixed(3)} MB` ;
            }
            return result;
        }},
        { field: 'record.shouldDecideRecord.executionTime', headerName: 'Should Time', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";

            if(params.row.record.shouldDecideRecord != undefined && params.row.record.shouldDecideRecord.executionTime != undefined){
                result =  params.row.record.shouldDecideRecord.executionTime.toFixed(5);
            }

            return result;
        }},
        { field: 'record.shouldDecideRecord.ememoryUsage', headerName: 'Should Memory', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";
            // "used_heap_size": 1434296
            if(params.row.record.shouldDecideRecord != undefined && params.row.record.shouldDecideRecord.memoryUsage != undefined){
                result = `${(params.row.record.shouldDecideRecord.memoryUsage["used_heap_size"]/1000000).toFixed(3)} MB` ;
            }
            return result;
        }},
        { field: 'record.probabilityRecord.executionTime', headerName: 'Decide Time', sortable: true, width: 140, valueGetter: (params) => {

            let result = "";

            if(params.row.record.probabilityRecord != undefined && params.row.record.probabilityRecord.executionTime != undefined){
                result =  params.row.record.probabilityRecord.executionTime.toFixed(5);
            }

            return result;
        }},
        { field: 'record.probabilityRecord.memoryUsage', headerName: 'Decide Memory', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";
            // "used_heap_size": 1434296
            if(params.row.record.probabilityRecord != undefined && params.row.record.probabilityRecord.memoryUsage != undefined){
                result = `${(params.row.record.probabilityRecord.memoryUsage["used_heap_size"]/1000000).toFixed(3)} MB` ;
            }
            return result;
        }},
        { field: 'record.actionRecord.executionTime', headerName: 'Action Time', sortable: true, width: 140, valueGetter: (params) => {

            let result = "";

            if(params.row.record.actionRecord != undefined && params.row.record.actionRecord.executionTime != undefined){
                result =  params.row.record.actionRecord.executionTime.toFixed(5);
            }

            return result;
        }},
        { field: 'record.actionRecord.memoryUsage', headerName: 'Action Memory', sortable: true, width: 140, valueGetter: (params) => {
            let result = "";
            // "used_heap_size": 1434296
            if(params.row.record.actionRecord != undefined && params.row.record.actionRecord.memoryUsage != undefined){
                result = `${(params.row.record.actionRecord.memoryUsage["used_heap_size"]/1000000).toFixed(3)} MB` ;
            }
            return result;
        }},
        { field: 'decide log', headerName: 'Activate Log', sortable: true, width: 200, valueGetter: (params) => {
            let result = "";

            if(params.row.record.shouldDecideRecord.record.resultType == "composite"){
                let finalValue = params.row.record.shouldDecideRecord.record.value;

                let recordList = params.row.record.shouldDecideRecord.record.recordList;

                let reducedList = recordList.map((recordInfo) => {
                    const {name, resultType, validity, value, eventName, ...rest} = recordInfo.record;

                    return {
                        name: v.replaceAll(name, "TriggerCondition", ""),
                        ...rest
                    };
                });
                if( reducedList.length > 1){
                    result = JSON.stringify(reducedList);
                }
                else{
                    result = JSON.stringify(reducedList[0]);
                }
            }
            else{
                let recordValue = params.row.record.shouldDecideRecord.record;

                result = recordValue.type;
                
                if(recordValue.type == "error"){
                    const {value, type, message} = recordValue;

                    result = JSON.stringify({type, message});

                }
                else{

                    const {name, resultType, validity, value, eventName, ...rest} = params.row.record.shouldDecideRecord.record;

                    result = JSON.stringify({
                        name: v.replaceAll(name, "TriggerCondition", ""),
                        ...rest
                    });
                }
                
                
                /*

                const {name, resultType, validity, value, eventName, ...rest} = params.row.record.shouldDecideRecord.record;

                result = JSON.stringify({
                    name: v.replaceAll(name, "TriggerCondition", ""),
                    ...rest
                });
                */
            }
            return result;
        }},
        { field: 'decide action', headerName: 'Decide Action', sortable: true, width: 180, valueGetter: (params) => {
            let result = "";
            // probabilityRecord.record.meta.meta_info.action
            
            let probabilityRecord = params.row.record.probabilityRecord;

            if(probabilityRecord != undefined && probabilityRecord.record != null){
                let record = probabilityRecord.record;
                if( record != null && probabilityRecord.record.meta != undefined && record.meta.meta_info != undefined && record.meta.meta_info.action != undefined){
                    result = record.meta.meta_info.action;
                }
            }
            
            return result;
        }},
        { field: 'decide info', headerName: 'Decide Info', sortable: true, width: 350, valueGetter: (params) => {
            let result = "";

            let probabilityRecord = params.row.record.probabilityRecord;
            if( probabilityRecord != undefined && probabilityRecord != null &&  probabilityRecord.record != undefined){
                result = JSON.stringify(probabilityRecord.record)
            }
            else if(probabilityRecord != undefined && probabilityRecord != null){
                result = JSON.stringify(probabilityRecord);
            }
            else{
                // nothing
                
            }

            /*
            let record = params.row.record.probabilityRecord.record;
            result = JSON.stringify(record);
            */
            return result;
        }},
        { field: 'action_meta', headerName: 'Action Meta', sortable: true, width: 350, valueGetter: (params) => {
            let result = "";
            console.log(JSON.stringify(params.row.record));
            let actionRecord = params.row.record.actionRecord != undefined? params.row.record.actionRecord: params.row.record.actionReord
            if( actionRecord != undefined && actionRecord.record.metaObj != undefined){
                result = JSON.stringify(actionRecord.record.metaObj)
            }
            return result;
        }},
        { field: 'action_value', headerName: 'Action Result', sortable: true, width: 350, valueGetter: (params) => {
            let result = "";

            let actionRecord = params.row.record.actionRecord;// != undefined? params.row.record.actionRecord: params.row.record.actionReord
            if( actionRecord != undefined &&  actionRecord.record.value != undefined){
                result = JSON.stringify(actionRecord.record.value)
            }
            else if(actionRecord != undefined && actionRecord != null && actionRecord.record.type == "error"){
                result = JSON.stringify(actionRecord);
            }
            else if(actionRecord != undefined && actionRecord != null && actionRecord.record.action == "No Action"){
                // nothing
            }
            else{
                // nothing
                
            }
            return result;
        }},
        { field: 'generatedTimestamp', headerName: 'Created At', sortable: true, width: 140, valueGetter: (params) => {
            return DateTime.fromISO(params.row.generatedTimestamp).toISO();
        } },
        { field: 'generatedTimestampLocal', headerName: 'Created At (Your Time)', sortable: true, width: 140, valueGetter: (params) => {
            return DateTime.fromISO(params.row.generatedTimestamp).toLocaleString(DateTime.DATETIME_FULL);
        } },
        /*
        {
            field: 'state.timezone', headerName: 'Timezone', sortable: true, width: 180, valueGetter: (params) => {
                let result = "";
                if (params.row.state != undefined && params.row.state.timezone != undefined) {
                    result = params.row.state.timezone;
                }
                return result;
            }
        },
        */
        /*
        {
          field: 'executionResult',
          headerName: 'Execution Result',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.convertExecutionResultToString(params.row.executionResult),
        },
        {
          field: 'createAtYourTime',
          headerName: 'Created At (your time)',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          description: 'This column has a value getter and is not sortable.',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          params.row.createdAt,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          //width: 160,
          valueGetter: (params) =>
          params.row.updatedAt,
        },
        {
          field: 'userInfoCache',
          headerName: 'User Info Cache',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
        },
        */
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

//export default NotificationTable;

//export default Hero;

/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/