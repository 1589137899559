import React, {useState, useEffect} from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Main from "./views/Main";
import Profile from "./views/Profile";
import NotificationPage from "./views/NotificationPage";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import MessagePage from "./views/MessagePage";
import UserPage from "./views/UserPage";
import PrivacyPage from "./views/PrivacyPage";
import ResponsePage from "./views/ResponsePage";
import CredentialPage from "./views/CredentialPage";
import SurveyPage from "./views/SurveyPage";
import RecordPage from "./views/RecordPage";
import ActionRecordPage from "./views/ActionRecordPage";
import AnalyticPage from "./views/AnalyticPage";
import UserEditPage from "./views/UserEditPage";
import EventPage from "./views/EventPage";
import UserRecordPage from "./views/UserRecordPage";


initFontAwesome();

export const UserContext = React.createContext();

const App = () => {
  
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();

  const {
    isLoading, error, isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [roleList, setRoleList] = useState(undefined);

  const callApi = async (domain=apiOrigin, path="/api/external") => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log(response.status, response.statusText);

        /*
        setState({
          ...state,
          showResult: true,
          apiMessage: response,
        });
        */

        //setApiMessage({ok: response.ok, status: response.status, statusText:response.statusText});


      } else {
        const responseData = await response.json();
        console.log(`Rolelist: ${JSON.stringify(responseData)}`);

        setRoleList(responseData);

        /*
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
        });
        */
      }

      
    } catch (error) {
      console.log(`catch error: ${error.error}`);
      //setResponseError(error.error);

      /*
      setState({
        ...state,
        error: error.error
      });
      */
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(`useEffect, isAuthenticated:${isAuthenticated}, roleList: ${roleList}`);
    if(isAuthenticated && roleList == undefined){
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/auth0/role");
    }
      
  });

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    // get roles
    console.log();



  }

  return (

    <Router history={history}>
      <UserContext.Provider value={roleList}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact 
              component={Home} 
              />
              <Route path="/main" exact component={Main} />
              <Route path="/profile" component={Profile} />
              <Route path="/notification" exact  component={NotificationPage} />
              <Route path="/message" exact  component={MessagePage} />
              <Route path="/user" exact  component={UserPage} />
              <Route path="/record" exact  component={RecordPage} />
              <Route path="/action_record" exact  component={ActionRecordPage} />
              <Route path="/analytic" exact  component={AnalyticPage} />
              <Route path="/survey" exact  component={SurveyPage} />
              <Route path="/response" exact  component={ResponsePage} />
              <Route path="/event" exact  component={EventPage} />
              <Route path="/credential" exact  component={CredentialPage} />
              <Route path="/privacy" exact  component={PrivacyPage} />
              <Route path="/user-edit/:target_username"  component={UserEditPage} />
              <Route path="/user-record/:target_username"  component={UserRecordPage} />
            </Switch>
          </Container>
          <Footer />
        </div>

      </UserContext.Provider>

    </Router>
  );
};

export default App;

// <Route path="/external-api" component={ExternalApi} />