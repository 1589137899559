import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from "luxon";

export default function SurveyTable({ itemList }) {
    const columns = [
        { field: 'label', headerName: 'Label', sortable: true, width: 100 },
        { field: 'username', headerName: 'Username', sortable: true, width: 100 },
        { field: 'window', headerName: 'Window', sortable: true, width: 100 },
        { field: 'title', headerName: 'Title', sortable: true, width: 100 },
        { field: 'prompt_list', headerName: 'Question List', sortable: true, width: 600, valueGetter: (params) => {
            let result = "";
            if (params.row.prompt_list != undefined) {
                // assume to be a list
                let reducedList = params.row.prompt_list.map((promptInfo) => {
                    const {name} = promptInfo;
                    if( promptInfo.group == "random-half"){
                        return `(R) ${name}`;
                    }
                    else{
                        return name;
                    }
                    return name;
                });
                result = reducedList.join(", "); //JSON.stringify(reducedList);
            }

            return result;
        } },
        { field: 'created_at', headerName: 'Created At', sortable: true, width: 140, valueGetter: (params) => {
            console.log(`typeof params.row.created_at: ${typeof params.row.created_at} `);
            return DateTime.fromISO(params.row.created_at).toLocaleString(DateTime.DATETIME_FULL);
        } },
        /*
        {
            field: 'created_at',
            headerName: 'Created At',
            sortable: true,
            width: 140
        }
        ,
        {
            field: 'state.timezone', headerName: 'Timezone', sortable: true, width: 180, valueGetter: (params) => {
                let result = "";
                if (params.row.state != undefined && params.row.state.timezone != undefined) {
                    result = params.row.state.timezone;
                }
                return result;
            }
        },
        */
        /*
        {
          field: 'executionResult',
          headerName: 'Execution Result',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.convertExecutionResultToString(params.row.executionResult),
        },
        {
          field: 'createAtYourTime',
          headerName: 'Created At (your time)',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          description: 'This column has a value getter and is not sortable.',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          params.row.createdAt,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          //width: 160,
          valueGetter: (params) =>
          params.row.updatedAt,
        },
        {
          field: 'userInfoCache',
          headerName: 'User Info Cache',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
        },
        */
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

//export default NotificationTable;

//export default Hero;

/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/