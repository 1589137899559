import React, { Fragment, useState, useEffect } from "react";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import AnalyticTable from "../components/AnalyticTable";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';


const AnalyticPage = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();

  const {
    isLoading, error, isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();


  const [itemList, setItemList] = useState(undefined);
  const [startDate, setStartDate] = useState(DateTime.now().startOf("day").toJSDate());
  const [endDate, setEndDate] = useState(DateTime.now().startOf("day").plus({ days: 1 }).toJSDate());

  const callApi = async (domain = apiOrigin, path = "/api/analytics") => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log(response.status, response.statusText);

      } else {
        const responseData = await response.json();
        console.log(`ItemList: ${JSON.stringify(responseData)}`);

        setItemList(responseData);
      }


    } catch (error) {
      console.log(`catch error: ${error}`);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(`useEffect, isAuthenticated:${isAuthenticated}, itemList: ${itemList}`);
    // if (isAuthenticated && itemList == undefined) {
    if (isAuthenticated) {
      // decide-true, decide-false, or all
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/analytics/${"all"}/${DateTime.fromJSDate(startDate).toISO()}/${DateTime.fromJSDate(endDate).toISO()}`);
    }
  }, [startDate, endDate]);

  if (isAuthenticated) {
    console.log(`user: ${JSON.stringify(user, null, 2)}`);
  }




  console.log(`AnalyticPage`);

  return (<Fragment>
    <h1>Analytic</h1>
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
      <DatePicker
        label="Start Date"
        value={DateTime.fromJSDate(startDate)}
        onChange={(newValue) => {
          setStartDate(newValue.startOf('day').toJSDate());
          setEndDate(newValue.startOf("day").plus({ days: 1 }).toJSDate());
        }}
      />
    </LocalizationProvider>
    <div>{DateTime.fromJSDate(startDate).toLocaleString(DateTime.DATETIME_FULL)} to {DateTime.fromJSDate(endDate).toLocaleString(DateTime.DATETIME_FULL)}</div>
    <br />
    <ObjectListExortToolbar filePrefix={"Analytic"} infoList={itemList} userInfo={user}></ObjectListExortToolbar>
    <br />
    <AnalyticTable itemList={itemList}></AnalyticTable>
  </Fragment>);
};

export default AnalyticPage;
