import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";

const authConfig = require("../auth_config.json");





export const ProfileComponent = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();


  const {
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [showResult, setShowResult] = useState(true);
  const [apiMessage, setApiMessage] = useState([]);
  const [responseError, setResponseError] = useState(null);

  let userId = user.sub;

  // now try to get role
  
  const callApi = async (domain=apiOrigin, path="/api/external") => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log(response.status, response.statusText);

        /*
        setState({
          ...state,
          showResult: true,
          apiMessage: response,
        });
        */

        setApiMessage({ok: response.ok, status: response.status, statusText:response.statusText});


      } else {
        const responseData = await response.json();
        console.log(responseData);

        setApiMessage(responseData);

        /*
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
        });
        */
      }

      
    } catch (error) {
      console.log(`catch error: ${error.error}`);
      setResponseError(error.error);

      /*
      setState({
        ...state,
        error: error.error
      });
      */
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if(apiMessage == "")
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/auth0/role");
  });

 
  

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.username}</p>
          <p className="lead text-muted">Role: {apiMessage.map((roleInfo) => {return roleInfo.name;}).join(",")}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>

      <div>Roles: </div>
      <Row>
        <Highlight>{JSON.stringify(apiMessage, null, 2)}</Highlight>
      </Row>

      
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
