import React, { Fragment, useState, useEffect } from "react";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";
import EventTable from "../components/EventTable";
const EventPage = () => {
    const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } = getConfig();

    const {
      isLoading, error, isAuthenticated,
      user,
      getAccessTokenSilently,
      loginWithPopup,
      getAccessTokenWithPopup,
    } = useAuth0();
  
  
    const [itemList, setItemList] = useState(undefined);
    const [endDate, setEndDate] = useState(DateTime.now().toJSDate());
    const [startDate, setStartDate] = useState(DateTime.now().minus({months: 3}).toJSDate());
  
    const callApi = async (domain = apiOrigin, path = "/api/events") => {

      try {
        const token = await getAccessTokenSilently();
  
        const response = await fetch(`${domain}${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          console.log(response.status, response.statusText);
  
        } else {
          const responseData = await response.json();
          console.log(`ItemList: ${JSON.stringify(responseData)}`);
  
          setItemList(responseData);
        }
  
  
      } catch (error) {
        console.log(`catch error: ${error.error}`);
      }
    };
  
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      console.log(`useEffect, isAuthenticated:${isAuthenticated}, itemList: ${itemList}`);
      if (isAuthenticated && itemList == undefined) {
        callApi(`${process.env.REACT_APP_API_SERVER_URL}`, `/api/events/${"all"}/${DateTime.fromJSDate(startDate).toISO()}/${DateTime.fromJSDate(endDate).toISO()}`);
      }
  
    });
  
    if (isAuthenticated) {
      console.log(`user: ${JSON.stringify(user, null, 2)}`);
    }




    console.log(`EventPage`);

    return (<Fragment>
        <h1>Event</h1>
        <div>{DateTime.fromJSDate(startDate).toLocaleString(DateTime.DATETIME_FULL)} to {DateTime.fromJSDate(endDate).toLocaleString(DateTime.DATETIME_FULL)}</div>
        <br />
        <ObjectListExortToolbar filePrefix={"Event"} infoList={itemList} userInfo={user}></ObjectListExortToolbar>
        <br />
        <EventTable itemList={itemList}></EventTable>
    </Fragment>);
};

export default EventPage;
