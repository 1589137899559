import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";

const Home = () => {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  if(isAuthenticated){
    console.log(`user: ${JSON.stringify(user, null, 2)}`);
    return <Redirect to="/main" />;
  }

  return (<Fragment>
    <Hero />
  </Fragment>);
};

export default Home;
