const contentData = [
  {
    title: "View action records",
    link: "/action_records",
    description:
      "See list of action records."
  },
  {
    title: "View events",
    link: "/event",
    description:
      "See list of events."
  },
  {
    title: "View notifications",
    link: "/notification",
    description:
      "See list of notifications."
  },
  {
    title: "View messages",
    link: "/message",
    description:
      "See list of messages."
  },
  {
    title: "View users",
    link: "/user",
    description:
      "See list of users."
  },
  {
    title: "View records",
    link: "/record",
    description:
      "See list of records."
  },
  {
    title: "View analytics",
    link: "/analytic",
    description:
      "See list of analytics."
  },
  {
    title: "View surveys",
    link: "/survey",
    description:
      "See list of surveys."
  },
  {
    title: "View responses",
    link: "/response",
    description:
      "See list of responses."
  },
  {
    title: "View credentials",
    link: "/credential",
    description:
      "See list of credentials."
  },
  {
    title: "[Placeholder] View participants",
    link: "/",
    description:
      "See participant details."
  },
  {
    title: "[Placeholder] View interaction analytics",
    link: "/",
    description:
      "View and download interaction analytics."
  },
  {
    title: "[Placeholder] View survey activity",
    link: "/",
    description:
      "View and download survey responses."
  },
  {
    title: "[Placeholder] View reward progress",
    link: "/",
    description:
      "View reward and dollar earned."
  }
];

export default contentData;
