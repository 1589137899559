import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export default function ResponseTable({ itemList }) {
    const columns = [
        { field: 'prompt_type', headerName: 'Prompt Type', sortable: true, width: 100 },
        { field: 'prompt_identifier', headerName: 'Identifier', sortable: true, width: 100 },
        { field: 'prompt_meta', headerName: 'Meta', sortable: true, width: 75, valueGetter: (params) => {
            let result = "";
            result = JSON.stringify(params.row.prompt_meta);
            return result;
        } },
        { field: 'type', headerName: 'Type', sortable: true, width: 100 },
        { field: 'username', headerName: 'Username', sortable: true, width: 150 },
        { field: 'content', headerName: 'content', sortable: true, width: 600, valueGetter: (params) => {
            let result = "";
            if (params.row.content != undefined) {
                // assume to be a list
                let reducedList = params.row.content.map((responseInfo) => {
                    const {name, response_list, is_complete} = responseInfo;
                    if(response_list.length > 0){
                        let value_list = response_list[0]["value_list"];

                        return {name, response: value_list};
                    }
                    else{
                        return {name, response: []};
                    }
                    
                });

                result = JSON.stringify(reducedList);
            }

            return result;
        }},
        {
            field: 'created_at',
            headerName: 'Created At',
            sortable: true,
            width: 140
        }
        /*
        {
            field: 'state.timezone', headerName: 'Timezone', sortable: true, width: 180, valueGetter: (params) => {
                let result = "";
                if (params.row.state != undefined && params.row.state.timezone != undefined) {
                    result = params.row.state.timezone;
                }
                return result;
            }
        },
        */
        /*
        {
          field: 'executionResult',
          headerName: 'Execution Result',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.convertExecutionResultToString(params.row.executionResult),
        },
        {
          field: 'createAtYourTime',
          headerName: 'Created At (your time)',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          description: 'This column has a value getter and is not sortable.',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          params.row.createdAt,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          //width: 160,
          valueGetter: (params) =>
          params.row.updatedAt,
        },
        {
          field: 'userInfoCache',
          headerName: 'User Info Cache',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
        },
        */
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

//export default NotificationTable;

//export default Hero;

/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/