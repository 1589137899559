//import { DateTime } from "luxon";
// use require syntax to import luxon DateTime
// https://stackoverflow.com/questions/66088715/how-to-import-luxon-into-a-react-project

// const { DateTime } = require("luxon");

import { DateTime } from "luxon";

export default class GeneralUtility {
  constructor() { }

  static diffDateTime(datetimeA, datetimeB, unit) {
    return datetimeB.diff(datetimeA, unit);
  }

  static getLocalTime(date, timezone) {
    return DateTime.fromJSDate(date).setZone(timezone);
}

  static getTSVStringFromObjectList(objectList) {
    let tsvString = "";

    if (objectList.length == 0) {
      return tsvString;
    }
    // prepare the tsv string
    let headerList = Object.keys(objectList[0]);
    let headerString = headerList.join("\t");
    tsvString += headerString + "\n";

    // now the content
    objectList.forEach((info) => {
      let contentList = headerList.map((columnName) => {
        //return info[columnName];
        return JSON.stringify(info[columnName]);
      });

      let contentString = contentList.join("\t");
      tsvString += contentString + "\n";
    });

    return tsvString;
  }
}

//export default GeneralUtility;
