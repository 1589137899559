import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import v from "voca";

export default function ActionRecordTable({ itemList }) {
    const columns = [
        {
            field: 'initiator_identifier', headerName: 'Initiator Id', sortable: true, width: 120, valueGetter: (params) => {
                let result = "";
                result = params.row.initiator_identifier
                result = v.replaceAll(result, "Demo", "");
                result = v.replaceAll(result, "MiWaves", "");
                result = v.replaceAll(result, "Trigger", "");
                return result;
            }
        },
        {
            field: 'name', headerName: 'Name', sortable: true, width: 120, valueGetter: (params) => {
                let result = "";
                result = params.row.name
                result = v.replaceAll(result, "Action", "");
                return result;
            }
        },
        {
            field: 'username', headerName: 'User', sortable: true, width: 65, valueGetter: (params) => {
                let result = "";
                result = params.row.username.split("@")[0];
                return result;
            }
        },
        {
            field: 'result', headerName: 'Result', sortable: true, width: 700, valueGetter: (params) => {
                let result = "";

                let actionResult = params.row.result;

                if (actionResult != undefined) {
                    result = JSON.stringify(actionResult)
                }
                return result;
            }
        },
        {
            field: 'created_at', headerName: 'Create At', sortable: true, width: 140, valueGetter: (params) => {
                return DateTime.fromISO(params.row.created_at).toISO();
            }
        },
        {
            field: 'updated_at', headerName: 'Update At', sortable: true, width: 140, valueGetter: (params) => {
                return DateTime.fromISO(params.row.updated_at).toISO();
            }
        },
        {
            field: 'initiator_provided_time', headerName: 'Provided Time', sortable: true, width: 140, valueGetter: (params) => {
                return DateTime.fromISO(params.row.initiator_provided_time).toISO();
            }
        },
        { field: 'initiator_type', headerName: 'Initiator Type', sortable: true, width: 100 },
        {
            field: 'action_index', headerName: 'Index', sortable: true, width: 60, valueGetter: (params) => {
                return `${params.row.action_index}`;
            }
        }
        /*
        {
            field: 'state.timezone', headerName: 'Timezone', sortable: true, width: 180, valueGetter: (params) => {
                let result = "";
                if (params.row.state != undefined && params.row.state.timezone != undefined) {
                    result = params.row.state.timezone;
                }
                return result;
            }
        },
        */
        /*
        {
          field: 'executionResult',
          headerName: 'Execution Result',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.convertExecutionResultToString(params.row.executionResult),
        },
        {
          field: 'createAtYourTime',
          headerName: 'Created At (your time)',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          description: 'This column has a value getter and is not sortable.',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          params.row.createdAt,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          //width: 160,
          valueGetter: (params) =>
          params.row.updatedAt,
        },
        {
          field: 'userInfoCache',
          headerName: 'User Info Cache',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
        },
        */
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

//export default NotificationTable;

//export default Hero;

/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/