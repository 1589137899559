import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export default function NotificationTable({ itemList }) {
    const columns = [
        { field: 'label', headerName: 'Label', sortable: true, width: 180 },
        { field: 'group', headerName: 'Group', sortable: true, width: 130 },
        { field: 'group_index', headerName: 'Index', sortable: true, width: 50 },
        {
            field: 'content_type',
            headerName: 'Content Type',
            width: 100
        },
        {
            field: 'notification_header',
            headerName: 'Notification Header',
            sortable: true,
            width: 140
        },
        {
            field: 'notification_body',
            headerName: 'Notification Body',
            sortable: true,
            width: 420
        },
        /*
        {
          field: 'executionResult',
          headerName: 'Execution Result',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.convertExecutionResultToString(params.row.executionResult),
        },
        {
          field: 'createAtYourTime',
          headerName: 'Created At (your time)',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          description: 'This column has a value getter and is not sortable.',
          sortable: true,
          width: 320,
          valueGetter: (params) =>
          params.row.createdAt,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          //width: 160,
          valueGetter: (params) =>
          params.row.updatedAt,
        },
        {
          field: 'userInfoCache',
          headerName: 'User Info Cache',
          sortable: false,
          //width: 160,
          valueGetter: (params) =>
          GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
        },
        */
    ];

    return (
        <div style={{ height: 640, width: '100%' }}>
            <DataGrid
                rows={itemList == undefined ? [] : itemList}
                getRowId={(row) => { return row._id; }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            //checkboxSelection
            />
        </div>
    )
};

//export default NotificationTable;

//export default Hero;

/*
<p className="lead">
This is the dashboard for research staff of the MiWaves study.
</p>
*/