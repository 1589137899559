import React, { Fragment, useState, useEffect } from "react";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { Redirect } from "react-router-dom";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import NotificationTable from "../components/NotificationTable";
import Button from "@mui/material/Button";
import UserTable from "../components/UserTable";
import Grid from "@mui/material/Grid";
import ObjectListExortToolbar from "../components/ObjectListExortToolbar";


const USER_DATA_ROUTE = "/api/users"; // "/api/users/eligibility";

const UserPage = () => {
  const { apiOrigin = `${process.env.REACT_APP_API_SERVER_URL}`, audience } =
    getConfig();

  const {
    isLoading,
    error,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [itemList, setItemList] = useState(undefined);

  const userDataRoute = "";

  const callApi = async (domain = apiOrigin, path = "/api/external") => {
    console.log(`callApi: ${domain}${path}`);
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${domain}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(`response.ok: ${JSON.stringify(response.ok)}`);

      if (!response.ok) {
        console.log(response.status, response.statusText);
      } else {
        const responseData = await response.json();
        console.log(`ItemList: ${JSON.stringify(responseData)}`);

        // should update the database, not the state variable
        switch (path) {
          case "/api/users":
          case USER_DATA_ROUTE:
            setItemList(responseData);
            break;
          case "/api/auth0/refresh-users":
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.log(`catch error: ${error.error}`);
      //setResponseError(error.error);

      /*
            setState({
              ...state,
              error: error.error
            });
            */
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(
      `useEffect, isAuthenticated:${isAuthenticated}, itemList: ${itemList}`
    );
    // load data from the database
    if (isAuthenticated && itemList == undefined) {
      callApi(`${process.env.REACT_APP_API_SERVER_URL}`, USER_DATA_ROUTE);
    }
  });

  if (isAuthenticated) {
    console.log(`user: ${JSON.stringify(user, null, 2)}`);
  }

  console.log(`UserPage`);

  return (
    <Fragment>
      <h1>User</h1>
      <br />
      <ObjectListExortToolbar
        filePrefix={"User"}
        infoList={itemList}
        userInfo={user}
      ></ObjectListExortToolbar>
      <br />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <span style={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() => {
                callApi(
                  `${process.env.REACT_APP_API_SERVER_URL}`,
                  "/api/auth0/refresh-users"
                ).then((response) => {
                  callApi(
                    `${process.env.REACT_APP_API_SERVER_URL}`,
                    USER_DATA_ROUTE
                  );
                });
              }}
            >
              Refresh from Auth0
            </Button>
          </span>
        </Grid>
        <Grid item xs={2}>
          <span style={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() => {
                callApi(
                  `${process.env.REACT_APP_API_SERVER_URL}`,
                  "/api/users/set-survey-window"
                ).then((response) => {
                  callApi(
                    `${process.env.REACT_APP_API_SERVER_URL}`,
                    USER_DATA_ROUTE
                  );
                });
              }}
            >
              Set Survey Window
            </Button>
          </span>
        </Grid>
        <Grid item xs={2}>
          <span style={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() => {
                callApi(
                  `${process.env.REACT_APP_API_SERVER_URL}`,
                  "/api/users/allocate-reward"
                ).then((response) => {
                  callApi(
                    `${process.env.REACT_APP_API_SERVER_URL}`,
                    USER_DATA_ROUTE
                  );
                });
              }}
            >
              Allocate reward
            </Button>
          </span>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <br />
      <UserTable itemList={itemList}></UserTable>
    </Fragment>
  );
};

export default UserPage;

/*
<Grid item xs={2}>
<span style={{ float: "right" }}><Button
    variant="contained"
    onClick={() => {
        callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users/set-all-timezone")
            .then((response) => {
                callApi(`${process.env.REACT_APP_API_SERVER_URL}`, "/api/users")
            })
    }}
>
    Set timezone
</Button></span>

</Grid>
*/
