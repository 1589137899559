import React from "react";
import logo from "../assets/logo.svg";
const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img className="mb-3 app-logo" src={logo} alt="Logo" width="60" />
    <p>
      Built by <a
        href="https://peiyaoh.github.io/"
        target="_blank"
        rel="noopener noreferrer"
      >Pei-Yao Hung</a
      > with &#129505;
    </p>
  </footer>
);

export default Footer;
