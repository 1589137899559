import React from "react";
import { DataGrid, GridColDef, GridValueGetterParams, GridApi } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import GeneralUtility from "../utils/GeneralUtility";

export default function UserTable({ itemList }) {
  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          //let username = params.row.username;

          // now, navigatae to the user-edit page



          /*
          const api = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
          */
        };

        return <Link to={`/user-edit/${params.row.username}`}><Button onClick={onClick}>Edit</Button></Link>;
      }
    },
    {
      field: "view_record",
      headerName: "View Record",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          //let username = params.row.username;

          // now, navigatae to the user-edit page



          /*
          const api = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
          */
        };

        return <Link to={`/user-record/${params.row.username}`}><Button onClick={onClick}>View Record</Button></Link>;
      }
    },
    { field: 'username', headerName: 'Username', sortable: true, width: 180 },
    {
      field: 'study_parameter.status', headerName: 'Status', sortable: true, width: 100, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["status"] != undefined) {
          result = `${params.row.study_parameter["status"]}`;
        }

        return result;
      }
    },
    {
      field: 'study_parameter.survey.history.content.last24', headerName: 'No Res. 24', sortable: true, width: 100, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["survey"] != undefined && params.row.study_parameter["survey"]["history"] != undefined && params.row.study_parameter["survey"]["history"]["content"] != undefined) {
          let surveyContentHistory = params.row.study_parameter["survey"]["history"]["content"];   
          let startDate = DateTime.now().minus({ hours: 24 }).toJSDate();
          let targetSurveyCount = 2;

          console.log(`username: ${params.row.username}`);

          console.log(`surveyContentHistory: ${
            surveyContentHistory.map((surveyContent, index) => {
              return DateTime.fromISO(surveyContent["intended_at"]);
            })}`);

          console.log(`startDate: ${startDate}`);

          // filter history by created_at in the last 24 hours
          /*
          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            return DateTime.fromJSDate(surveyContent["intended_at"]) > DateTime.fromJSDate(startDate);
          });
          */

          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            let targetDate = DateTime.fromISO(surveyContent["intended_at"]);
            console.log("startDate:", startDate, "targetDate:", targetDate.toJSDate());
            let diffObject = DateTime.fromJSDate(startDate).diff(targetDate, "seconds").toObject();
            console.log(`diffObject: ${JSON.stringify(diffObject)}`);
            let diffInSeconds = diffObject["seconds"];
            console.log(`diffInSeconds: ${diffInSeconds}`);
            return diffInSeconds <= 0;
          });

          console.log(`filteredSurveyContentHistory: ${filteredSurveyContentHistory}`);

          if (filteredSurveyContentHistory.length < targetSurveyCount) {
            result = `N/A (${filteredSurveyContentHistory.length}/${surveyContentHistory.length})`;
          }
          else {
            let completeSurveyEntryList = filteredSurveyContentHistory.filter((surveyContent) => {
              return surveyContent["status"] == "complete";
            });

            console.log(`completeSurveyEntryList: ${completeSurveyEntryList}`);

            result = `${completeSurveyEntryList.length == 0} (${completeSurveyEntryList.length})`;
          }
        }

        console.log(`result: ${result}`);

        return result;
      }
    },
    {
      field: 'study_parameter.survey.history.content.last48', headerName: 'No Res. 48', sortable: true, width: 100, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["survey"] != undefined && params.row.study_parameter["survey"]["history"] != undefined && params.row.study_parameter["survey"]["history"]["content"] != undefined) {
          let surveyContentHistory = params.row.study_parameter["survey"]["history"]["content"];
          let startDate = DateTime.now().minus({ hours: 48 }).toJSDate();
          let targetSurveyCount = 4;

          // filter history by created_at in the last 48 hours
          /*
          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            return DateTime.fromJSDate(surveyContent["intended_at"]) > DateTime.fromJSDate(startDate);
          });
          */
          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            let targetDate = DateTime.fromISO(surveyContent["intended_at"]);
            console.log("startDate:", startDate, "targetDate:", targetDate.toJSDate());
            let diffObject = DateTime.fromJSDate(startDate).diff(targetDate, "seconds").toObject();
            console.log(`diffObject: ${JSON.stringify(diffObject)}`);
            let diffInSeconds = diffObject["seconds"];
            console.log(`diffInSeconds: ${diffInSeconds}`);
            return diffInSeconds <= 0;
          });

          if (filteredSurveyContentHistory.length < targetSurveyCount) {
            result = `N/A (${filteredSurveyContentHistory.length}/${surveyContentHistory.length})`;
          }
          else {
            let completeSurveyEntryList = filteredSurveyContentHistory.filter((surveyContent) => {
              return surveyContent["status"] == "complete";
            });

            result = `${completeSurveyEntryList.length == 0} (${completeSurveyEntryList.length})`;
          }
        }

        return result;
      }
    },
    {
      field: 'study_parameter.survey.history.content.last72', headerName: 'No Res. 72', sortable: true, width: 100, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["survey"] != undefined && params.row.study_parameter["survey"]["history"] != undefined && params.row.study_parameter["survey"]["history"]["content"] != undefined) {
          let surveyContentHistory = params.row.study_parameter["survey"]["history"]["content"];
          let startDate = DateTime.now().minus({ hours: 72 }).toJSDate();
          let targetSurveyCount = 6;

          // filter history by created_at in the last 72 hours
          /*
          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            return DateTime.fromJSDate(surveyContent["intended_at"]) > DateTime.fromJSDate(startDate);
          });
          */
          let filteredSurveyContentHistory = surveyContentHistory.filter((surveyContent) => {
            let targetDate = DateTime.fromISO(surveyContent["intended_at"]);
            console.log("startDate:", startDate, "targetDate:", targetDate.toJSDate());
            let diffObject = DateTime.fromJSDate(startDate).diff(targetDate, "seconds").toObject();
            console.log(`diffObject: ${JSON.stringify(diffObject)}`);
            let diffInSeconds = diffObject["seconds"];
            console.log(`diffInSeconds: ${diffInSeconds}`);
            return diffInSeconds <= 0;
          });
          

          if (filteredSurveyContentHistory.length < targetSurveyCount) {
            result = `N/A (${filteredSurveyContentHistory.length}/${surveyContentHistory.length})`;
          }
          else {
            let completeSurveyEntryList = filteredSurveyContentHistory.filter((surveyContent) => {
              return surveyContent["status"] == "complete";
            });

            result = `${completeSurveyEntryList.length == 0} (${completeSurveyEntryList.length})`;
          }
        }

        return result;
      }
    },
    {
      field: 'study_parameter.effective_since', headerName: 'Since', sortable: true, width: 180, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["effective_since"] != undefined) {
          if(params.row.state.timezone != undefined){
            result = `${ GeneralUtility.getLocalTime(DateTime.fromISO(params.row.study_parameter["effective_since"]).toJSDate(), params.row.state.timezone) }`;
          }
          else{
            result = `${params.row.study_parameter["effective_since"]}`;
          }
          
        }

        return result;
      }
    },
    {
      field: 'study_parameter.effective_before', headerName: 'Before', sortable: true, width: 180, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["effective_before"] != undefined) {
          if(params.row.state.timezone != undefined){
            result = `${ GeneralUtility.getLocalTime(DateTime.fromISO(params.row.study_parameter["effective_before"]).toJSDate(), params.row.state.timezone) }`;
          }
          else{
            result = `${params.row.study_parameter["effective_before"]}`;
          }
        }

        return result;
      }
    },
    {
      field: 'study_parameter.start', headerName: 'Active At', sortable: true, width: 180, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["status"] != undefined) {
          result = `${params.row.study_parameter["active_at"]}`;
        }

        return result;
      }
    },
    {
      field: 'study_parameter.end', headerName: 'Inactive At', sortable: true, width: 180, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["status"] != undefined && params.row.study_parameter["status"] == "inactive") {
          result = `${params.row.study_parameter["inactive_at"]}`;
        }

        return result;
      }
    },
    //{ field: 'account_status', headerName: 'Account Status', sortable: true, width: 120 },
    //{ field: 'name', headerName: 'Name', sortable: true, width: 180 },
    {
      field: 'state.timezone', headerName: 'Timezone', sortable: true, width: 150, valueGetter: (params) => {
        let result = "";
        if (params.row.state != undefined && params.row.state.timezone != undefined) {
          result = params.row.state.timezone;
        }
        return result;
      }
    },
    {
      field: 'uuid', headerName: 'Device Id', sortable: true, width: 150, valueGetter: (params) => {
        let result = "";
        if (params.row.uuid != undefined) {
          result = params.row.uuid;
        }
        return result;
      }
    },
    {
      field: 'push_token', headerName: 'Push Token', sortable: true, width: 150, valueGetter: (params) => {
        let result = "";
        if (params.row.push_token != undefined) {
          result = params.row.push_token;
        }
        return result;
      }
    },
    {
      field: 'preference.survey_window', headerName: 'Survey Window', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.preference != undefined && params.row.preference["survey_window"] != undefined) {
          result = JSON.stringify(params.row.preference["survey_window"]);
        }

        return result;
      }
    },
    {
      field: 'study_parameter.reward.all', headerName: 'Reward', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["reward"] != undefined && params.row.study_parameter["reward"]["all"] != undefined) {
          let rewardList = params.row.study_parameter["reward"]["all"];

          //console.log(`rewardList: ${JSON.stringify(rewardList)}`);
          //console.log(`typseof rewardList: ${typeof rewardList}`);
          if (rewardList != undefined) {
            rewardList = rewardList.map((rInfo) => {
              if (rInfo["status"] == "created") {
                return rInfo.value;
              }
              else {
                return `(${rInfo.value})`;
              }

            });

            result = rewardList.join(", ");
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.reward.total', headerName: 'Total Reward', sortable: true, width: 360, valueGetter: (params) => {
        let result = "$0";
        if (params.row.study_parameter != undefined && params.row.study_parameter["reward"] != undefined && params.row.study_parameter["reward"]["all"] != undefined) {
          let rewardList = params.row.study_parameter["reward"]["all"];
          let total = 0;

          //console.log(`rewardList: ${JSON.stringify(rewardList)}`);
          //console.log(`typseof rewardList: ${typeof rewardList}`);
          if (rewardList != undefined) {
            rewardList = rewardList.forEach((rInfo) => {
              if (rInfo["status"] == "claimed") {
                total += rInfo.value;
              }
            });

            result = `\$${total}`; //rewardList.join(", ");                
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.survey.notification', headerName: 'EMA Notification', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["survey"] != undefined && params.row.study_parameter["survey"]["notification"] != undefined) {
          let emaNotification = params.row.study_parameter["survey"]["notification"];

          console.log(`emaNotification: ${JSON.stringify(emaNotification)}`);
          if (emaNotification != undefined) {
            const { info, ...rest } = emaNotification;
            const { to, ...infoRest } = info;

            let reducedNotification = { info: { ...infoRest }, ...rest };


            result = JSON.stringify(reducedNotification);
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.intervention.decision', headerName: 'Intervention Decision', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["intervention"] != undefined && params.row.study_parameter["intervention"]["decision"] != undefined) {
          let interventionDecision = params.row.study_parameter["intervention"]["decision"];

          console.log(`interventionDecision: ${JSON.stringify(interventionDecision)}`);
          if (interventionDecision != undefined) {
            /*
            const {info, ...rest} = interventionContent;
            const {to, ...infoRest} = info;

            let reducedNotification = {info: {...infoRest}, ...rest};
            */

            result = JSON.stringify(interventionDecision);
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.intervention.content', headerName: 'Intervention Content', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["intervention"] != undefined && params.row.study_parameter["intervention"]["content"] != undefined) {
          let interventionContent = params.row.study_parameter["intervention"]["content"];

          console.log(`interventionContent: ${JSON.stringify(interventionContent)}`);
          if (interventionContent != undefined) {
            /*
            const {info, ...rest} = interventionContent;
            const {to, ...infoRest} = info;

            let reducedNotification = {info: {...infoRest}, ...rest};
            */

            result = JSON.stringify(interventionContent);
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.intervention.notification', headerName: 'Intervention Notification', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["intervention"] != undefined && params.row.study_parameter["intervention"]["notification"] != undefined) {
          let interventionNotification = params.row.study_parameter["intervention"]["notification"];

          console.log(`interventionNotification: ${JSON.stringify(interventionNotification)}`);
          if (interventionNotification != undefined) {
            const { info, ...rest } = interventionNotification;
            const { to, ...infoRest } = info;

            let reducedNotification = { info: { ...infoRest }, ...rest };


            result = JSON.stringify(reducedNotification);
          }

        }

        return result;
      }
    },
    {
      field: 'study_parameter.rl.data.current', headerName: 'RL Data', sortable: true, width: 360, valueGetter: (params) => {
        let result = "";
        if (params.row.study_parameter != undefined && params.row.study_parameter["rl"] != undefined && params.row.study_parameter["rl"]["data"] != undefined && params.row.study_parameter["rl"]["data"]["current"] != undefined) {
          let rlDataRecord = params.row.study_parameter["rl"]["data"]["current"];

          console.log(`rlDataRecord: ${JSON.stringify(rlDataRecord)}`);
          if (rlDataRecord != undefined) {
            /*
            const {info, ...rest} = rlDataRecord;
            const {to, ...infoRest} = info;

            let reducedNotification = {info: {...infoRest}, ...rest};
            */

            result = JSON.stringify(rlDataRecord);
          }

        }

        return result;
      }
    },
    { field: 'last_login', headerName: 'Last Login', sortable: true, width: 200 },
    {
      field: 'logins_count',
      headerName: 'Logins Count',
      width: 100
    },
    /*
    {
        field: 'created_at',
        headerName: 'Created At',
        sortable: true,
        width: 140
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        sortable: true,
        width: 140
    },
    {
      field: 'executionResult',
      headerName: 'Execution Result',
      sortable: false,
      //width: 160,
      valueGetter: (params) =>
      GeneralUtility.convertExecutionResultToString(params.row.executionResult),
    },
    {
      field: 'createAtYourTime',
      headerName: 'Created At (your time)',
      sortable: true,
      width: 320,
      valueGetter: (params) =>
      DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_FULL),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 320,
      valueGetter: (params) =>
      params.row.createdAt,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      sortable: true,
      //width: 160,
      valueGetter: (params) =>
      params.row.updatedAt,
    },
    {
      field: 'userInfoCache',
      headerName: 'User Info Cache',
      sortable: false,
      //width: 160,
      valueGetter: (params) =>
      GeneralUtility.extractUserKeyAttributesToString(params.row.userInfoCache),
    },
    */
  ];

  return (
    <div style={{ height: 640, width: '100%' }}>
      <DataGrid
        rows={itemList == undefined ? [] : itemList}
        getRowId={(row) => { return row._id; }}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      //checkboxSelection
      />
    </div>
  )
};

